import request from '../utils/request.js'

// 根据 用户ID 获取详细信息
export function classIndex(data){
    return request({
        url: '/api/system/user/findByid',
        method: 'post',
        data
    })
}

// 班级列表数据获取
export function getClassList(data){
    return request({
        url: '/api/sysclass/page',
        method: 'post',
        data
    })
}
// 院系列表数据获取
//export function getDepartmentList(data){
//    return request({
//        url: '/api/sysdepartment/list',
//        method: 'post',
//        data
//    })
//}

// 添加 单条 班级信息
export function addClassList(data){
    return request({
        url: '/api/sysclass/addClassItem',
        method: 'post',
        data
    })
}
// 修改 单条 班级信息
export function updateClassItem(data){
    return request({
        url: '/api/sysclass/updateClassItem',
        method: 'post',
        data
    })
}

// 修改班级信息
export function updateClassList(data){
    return request({
        url: '/api/sysclass/update',
        method: 'post',
        data
    })
}

// 删除班级信息
export function deleteClassList(id){
    return request({
        url: `/api/sysclass/remove/${id}`,
        method: 'post',
    })
}