<style lang="scss" scoped></style>

<template>
  <div class="app-container">
    <!-- 卡片视图 -->
    <el-card>
      <div class="info mb10">
        <span>分值：</span>
        <div class="block">
          <el-input-number v-model="queryInfo.minScore"></el-input-number>
        </div>
        <div class="block">
          <el-input-number v-model="queryInfo.maxScore"></el-input-number>
        </div>

        <span>院系：</span>
        <el-select v-model="queryInfo.departID" placeholder="请选择" clearable>
          <el-option v-for="item in deptList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </div>
      <div class="info">
        <span>班级名称：</span>
        <!-- 搜索 添加 -->
        <el-input placeholder="请输入班级名称" class="w180" v-model="queryInfo.searchName" clearable></el-input>

        <div>
          <el-button type="primary" @click="searchGradeList">搜索</el-button>
          <el-button type="primary" @click="addDialogVisible = true" plain>新增</el-button>
          <!-- 模板下载 -->
          <el-button style="height: 40px;" type="primary" plain class="ml10" @click="downloadTemplate()">模板下载</el-button>
          <!-- 导入数据信息 -->
          <el-upload class="upload-data" :action="imgBaseUrl + '/api/import/importClassData'" :on-success="upload"
            :on-error="uploadError" :show-file-list="false">
            <el-button type="primary" plain class="ml10">导入</el-button>
          </el-upload>
        </div>
      </div>

      <!-- 班级列表区域 -->
      <el-table :data="gradeList" border style="width: 100%;" class="mgt16">
        <el-table-column prop="id" label="序号" align="center" width="60">
          <template slot-scope="scope" class="w100">{{ scope.row.id }}</template>
        </el-table-column>
        <!-- 字段修改 -->
        <el-table-column prop="className" label="班级名称" align="left">
          <template slot-scope="scope">{{ scope.row.className }}</template>
        </el-table-column>
        <el-table-column prop="classNum" label="班级编号" align="left">
          <template slot-scope="scope">{{ scope.row.classNum }}</template>
        </el-table-column>
        <el-table-column prop="depName" label="院系" align="left">
          <template slot-scope="scope">{{ scope.row.depName }}</template>
        </el-table-column>
        <el-table-column prop="classTeacherName" label="班主任" align="left">
          <template slot-scope="scope">{{ scope.row.classTeacherName }}</template>
        </el-table-column>
        <el-table-column prop="classTeacherNum" label="班主任工号" align="left">
          <template slot-scope="scope">{{ scope.row.classTeacherNum }}</template>
        </el-table-column>
        <el-table-column prop="classTeacherPhone" label="班主任电话" align="left">
          <template slot-scope="scope">{{ scope.row.classTeacherPhone }}</template>
        </el-table-column>

        <el-table-column prop="classRate" label="分值系数" align="left">
          <template slot-scope="scope">{{ scope.row.classRate }}</template>
        </el-table-column>
        <el-table-column prop="classScore" label="考评得分" align="left">
          <template slot-scope="scope">{{ scope.row.classScore }}</template>
        </el-table-column>
        <el-table-column prop="rateScore" label="最终得分" align="left">
          <template slot-scope="scope">{{ scope.row.rateScore }}</template>
        </el-table-column>
        <el-table-column prop="studentCount" label="人数" align="left">
          <template slot-scope="scope">{{ scope.row.studentCount }}</template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="showEditDialog(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="removeById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo" :page-size="queryInfo.pageSize" layout="total, prev, pager, next, jumper"
        :total="total"></el-pagination>
    </el-card>

    <!-- 添加 班级 的对话框 -->
    <el-dialog title="添加班级" :visible.sync="addDialogVisible" width="650px" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="150px">
        <el-form-item label="班级名称" prop="className">
          <el-input v-model="addForm.className"></el-input>
        </el-form-item>
        <el-form-item label="班级编号" prop="classNum">
          <el-input v-model="addForm.classNum"></el-input>
        </el-form-item>
        <el-form-item label="分值系数" prop="classRate">
          <el-input v-model="addForm.classRate" type="number"></el-input>
        </el-form-item>
        <el-form-item label="所属院系" prop="depName">
          <el-select v-model="addForm.depId" placeholder="请选择">
            <el-option v-for="item in deptList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="照片" prop="photo">
          <img class="img1" :src="`${imgBaseUrl}${addForm.photo}${imgRightSub}`" alt=""
            @click="toSelectClassPhotoImgAdd();" />
          <el-upload ref="myUploadClassPhotoAdd" :action="imgBaseUrl + '/api/sysclass/uploadClassAvator'" :data="{
            depId: this.addForm.depId,
            classId: this.addForm.id,
          }" :headers="{ Authorization: this.token }" :limit="1" :show-file-list="false" multiple
            accept=".jpeg,.jpg,.png" :on-success="onSuccessUploadClassPhotoAdd" :disabled="!addForm.className ||
              !addForm.classNum ||
              !addForm.classRate
              ">
            <el-button id="btnSelectPhotoAdd" size="small" type="primary" :disabled="!addForm.className ||
              !addForm.classNum ||
              !addForm.classRate">
              上传照片
            </el-button>
          </el-upload>
          <p style="color:red">
            *必须优先填写班级名称、班级编码、分值系数和所属院系才可上传
          </p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改 班级 的对话框 -->
    <el-dialog title="修改班级" :visible.sync="editDialogVisible" width="650px" @close="editDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" label-width="150px">
        <el-form-item label="班级名称" prop="className">
          <el-input v-model="editForm.className"></el-input>
        </el-form-item>
        <el-form-item label="班级编号" prop="classNum">
          <el-input v-model="editForm.classNum"></el-input>
        </el-form-item>
        <el-form-item label="分值系数" prop="classRate">
          <el-input v-model="editForm.classRate" type="number"></el-input>
        </el-form-item>
        <el-form-item label="所属院系" prop="depName">
          <el-select v-model="editForm.depId" placeholder="请选择">
            <el-option v-for="item in deptList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="照片" prop="photo">
          <img class="img1" :src="`${imgBaseUrl}${editForm.photo}${imgRightSub}`" alt=""
            @click="toSelectClassPhotoImgEdit();" />
          <el-upload ref="myUploadClassPhotoEdit" :action="imgBaseUrl + '/api/sysclass/uploadClassAvator'" :data="{
            depId: this.editForm.depId,
            classId: this.editForm.id,
          }" :headers="{ Authorization: this.token }" :limit="1" :show-file-list="false" multiple
            accept=".jpeg,.jpg,.png" :on-success="onSuccessUploadClassPhotoEdit" :disabled="!editForm.className ||
              !editForm.classNum ||
              !editForm.classRate
              ">
            <el-button id="btnSelectPhotoEdit" size="small" type="primary" :disabled="!editForm.className ||
              !editForm.classNum ||
              !editForm.classRate">
              上传照片
            </el-button>
          </el-upload>
          <p style="color: red">
            *必须优先填写班级名称、班级编码、分值系数和所属院系才可上传
          </p>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveClassEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as user_api from "@/api/users";
import { getHttpUrl } from "@/utils/request";
import * as class_api from "@/api/class";
import { getToken } from "@/utils/token.js";
import { getNodeItemByID } from "@/utils/mine.js";
export default {
  data() {
    return {
      token: "",

      imgBaseUrl: getHttpUrl(),
      imgRightSub: "",

      // 院系列表
      deptList: [],

      // 获取班级列表查询参数对象
      queryInfo: {
        // 所选院系ID
        departID: null,
        // 分值区间
        minScore: 0,
        maxScore: 0,
        // 班级名称
        searchName: "",
        // 当前页数
        page: 1,
        // 每页显示多少数据
        pageSize: 10,
      },

      // 班级列表
      gradeList: [],
      total: 0,

      // 添加班级对话框
      addDialogVisible: false,
      // 班级添加
      addForm: {
        id: "-1",
        className: "",
        classNum: "",
        classRate: "1",
        depId: "",
        photo: "/noHead.png",
      },
      // 班级添加表单验证规则
      addFormRules: {
        classNum: [
          { required: true, message: "请输入班级编号", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "班级编号的长度在2～10个字",
            trigger: "blur",
          },
        ],
        className: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "班级名称的长度在2～50个字",
            trigger: "blur",
          },
        ],
        depId: [
          { required: true, message: "请选择所属院系", trigger: "blur" },
        ],
      },

      // 修改班级
      editDialogVisible: false,
      editForm: {},
      // 编辑班级表单验证
      editFormRules: {
        classNum: [
          { required: true, message: "请输入班级编号", trigger: "blur" },
        ],
        className: [{ required: true, message: "请输入班级名称", trigger: "blur" }],
      },
    };
  },
  // vue 在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
  created() {
    this.getDepartList();
    this.searchGradeList();
  },
  // vue 在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
  mounted() {
    this.token = getToken();
  },
  methods: {
    // 点击 网页图片，也能选 图片文件
    toSelectClassPhotoImgAdd() {
      getNodeItemByID("btnSelectPhotoAdd").click();
    },
    toSelectClassPhotoImgEdit() {
      getNodeItemByID("btnSelectPhotoEdit").click();
    },
    // 生成一个随机数
    randomNumImgRSub() {
      this.imgRightSub = "?t=" + Math.random().toString(36).slice(2);
    },

    // 获取院系列表
    getDepartList() {
      let { page, pageSize } = this;
      let params = {
        page,
        pageSize,
      };
      // 院系接口
      user_api.getSystemList(params).then((res) => (this.deptList = res.data.records));
    },
    // 搜索 班级 列表
    async searchGradeList() {
      let res = await class_api.getClassList(
        this.queryInfo
      );
      console.log(res);
      if (res.status !== 1) {
        return this.$message.error("获取班级列表失败！");
      }
      this.gradeList = res.data.rows;
      this.total = res.data.total;
      console.log(res, this.gradeList);
    },

    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      console.log(newSize);
      this.queryInfo.pageSize = newSize;
      this.searchGradeList();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      console.log(newSize);
      this.queryInfo.page = newSize;
      console.log(this.queryInfo);
      this.searchGradeList();
    },
    // 监听 添加班级对话框的关闭事件
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },
    // 上传成功
    onSuccessUploadClassPhotoAdd(response) {
      this.randomNumImgRSub();
      this.addForm.photo = response.data;
      // 在每一次上传请求结束后清除缓存，否则无法第二次选图
      this.$refs['myUploadClassPhotoAdd'].clearFiles();
    },
    // 上传成功
    onSuccessUploadClassPhotoEdit(response) {
      this.randomNumImgRSub();
      this.editForm.photo = response.data;
      // 在每一次上传请求结束后清除缓存，否则无法第二次选图
      this.$refs['myUploadClassPhotoEdit'].clearFiles();
    },
    // 添加班级
    add() {
      // 提交请求前，表单预验证
      this.$refs.addFormRef.validate(async (valid) => {
        // 表单预校验失败
        if (!valid) return;
        let res = await class_api.addClassList({ ...this.addForm, classscore: 100 });
        if (res.status !== 1) {
          this.$message.error("添加班级失败！");
          return;
        }
        this.$message.success("添加班级成功！");
        // 隐藏添加班级对话框
        this.addDialogVisible = false;
        this.searchGradeList();
      });
    },
    // 打开 编辑 窗口
    async showEditDialog(data) {
      let copy = Object.assign({}, data);
      this.editForm = copy;
      this.editDialogVisible = true;
    },
    // 监听修改班级对话框的关闭事件
    editDialogClosed() {
      // alert("点击空白区域隐藏");
      this.$refs.editFormRef.resetFields();
    },
    // 修改班级信息
    saveClassEdit() {
      // 提交请求前，表单预验证
      this.$refs.editFormRef.validate(async (valid) => {
        // 表单预校验失败
        if (!valid) return;
        let res = await class_api.updateClassItem(this.editForm);
        if (res.status !== 1) {
          this.$message.error("更新班级信息失败！");
        }
        // 隐藏添加班级对话框
        this.editDialogVisible = false;
        this.$message.success("更新班级信息成功！");
        this.searchGradeList();
      });
    },
    // 删除班级
    async removeById(id) {
      const confirmResult = await this.$confirm("确定删除该班级?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let res = await class_api.deleteClassList(id);
      if (res.status !== 1) return this.$message.error("删除班级失败！");
      this.$message.success("删除班级成功！");
      this.searchGradeList();
    },

    /**
     * 导入成功回调
     * @param {*} response 
     */
    upload(response) {
      if (response.status === -1) {
        // this.$message.error(response.msg);
        this.$confirm(response.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error'
        }).then(() => { }).catch(() => { });
      } else {
        this.$message({ message: '数据导入成功', type: 'success' });
        this.getDepartList();
        this.searchGradeList();
      }
    },

    /**
     * 文件上传失败回调
     */
    uploadError(err) {
      let me = this;
      console.log("err====== ", err);
      // me.$message.error("数据导入失败！");
      me.$confirm('数据导入失败！', '提示', {
        confirmButtonText: '确定',
        type: 'error'
      }).then(() => { }).catch(() => { });
    },

    /**
     * 模板下载
     */
    downloadTemplate() {
      window.location.href = this.imgBaseUrl + "/api/import/exportTemplate?rowType=3"
    }

  },
};
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  margin-bottom: 10px;

  span {
    line-height: 41px;
  }
}

.img1 {
  width: auto;
  height: 200px;
  cursor: pointer;
}

.upload-data {
  float: left;
}
</style>